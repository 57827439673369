<template>
  <div>
    <Header/> 
    <div class="contents_text_one">
      <!-- <router-link :to="{path:'/'}">
        <img class="contents_img_1_one" src="../../assets/content/logo_s.png">
      </router-link> -->
        <img class="contents_img_2_one" src="../../assets/index/add_icon.png">
        <div calss="contents_study_one">分享：{{title}}</div>
    </div>
    
    <div class="sv_share">
       <div style="padding: 20px; font-size: 18px;" id="content" v-html="content"></div>
       <div class="clear-btn_share copy-btn_share button_share" @click="copy()">點擊這裡複製上面的文字<br />並轉貼到任何地方分享</div>
    </div>
    <div style="width:100%;height:90px;background:#fff;"></div>

  </div>
</template>

<style>
.button_share{
  padding: 7px 20px;
}
.copy-btn_share{
    color: #989898;
    border: 1px solid #989898;
    border-radius: 12px;
}
  .sv_share{
    height: auto;
    width: 100%;
    background:#fff;
    min-height: 84vh;
  }
  .contents_study_one{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_text_one{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .contents_img_1_one{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .contents_img_2_one{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import {apiSharesDetail} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        title:'文字内容',
        content:'',
    }
  },
  created(){
    let id = this.$route.params.id;
    if(id && id>  0){
      apiSharesDetail(id).then(res=>{
        if(res.data){
          this.content = res.data.text_content;
        }
      }).catch(error=>{
        console.log(error);
      })
    }
  },
  methods:{
    copy(){
      let url = document.getElementById('content').innerText;
      let oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand("Copy") // 执行浏览器复制命令
      this.$message({
        message: '内容已複製',
        type: 'success'
      })
      oInput.remove()
    }
  }
}
</script>

